<template>
    <div id="main">
        <el-main>
            <div class="formBox">
                <div class="header">
                    <div class="handle">
                        <div style="font-size: 32px; font-weight: 400">报名信息表</div>
                    </div>
                    <div class="btnBox">
                        <el-button @click="saveInfo">保存</el-button>
                    </div>
                </div>
                <self-info
                        :selfInfos="selfInfo"
                        @getValues="getValue"
                        ref="selfInfos"
                ></self-info>

                <div class="btn">
                    <el-button
                            @click="back"
                            class="back"
                            style="width: 200px; height: 50px; font-size: 16px; margin-top: 30px"
                    >返回
                    </el-button
                    >
                    <el-button
                            :disabled="isDisable"
                            @click="saveInfo"
                            class="confirm"
                            style="width: 200px; height: 50px; font-size: 16px; margin-top: 30px"
                    >提交
                    </el-button
                    >
                </div>
            </div>
        </el-main>
    </div>
</template>

<script>
    import selfInfo from "c/selfInfo";
    import {getStep1} from "r/index/signUp";
    import {setStudentInfo} from "r/index/register";
    import {signUpInitReq} from "common/request";

    export default {
        data() {
            return {
                //个人信息
                selfInfo: {
                    //个人信息
                    name: "", //学生姓名
                    phone: "", //手机号
                    sex: 0, //性别 0男  1女
                    email: "", //邮箱
                    jiguan_code: undefined, //籍贯 籍贯code 【省份id+城市id】
                    shengyuandi_code: undefined, //生源code 【省份id+城市id】
                    birthday: "", //出生日期，类似20020624
                    nation: "", //民族
                    political_status: "", //政治面貌
                    start_school_date: "", //入学年份
                    category: "", //科类[文理科 1 文科 2 理科 3 选科]
                    gk_selectsubject: "", //选科科目【格式：3001#3002#3008】
                    foreign_language: "", //语种
                    address_code: "", //家庭住址省市code【省份id+城市id】
                    address: "", //家庭住址
                    familyinfo: [
                        //家庭信息
                        {
                            career: "",
                            educationdegree: "",
                            job: "",
                            name: "",
                            phone: "",
                            relation: "",
                            workplace: "",
                        },
                        {
                            career: "",
                            educationdegree: "",
                            job: "",
                            name: "",
                            phone: "",
                            relation: "",
                            workplace: "",
                        },
                    ],
                    schoolprovince: "", //学校省份code（id）通过【获取所有省份列表】获得
                    schoolcity: "", //学校城市code（id）通过【获取所有城市列表】获得
                    school: "", //学校code（id）通过【获取所有学校列表】获得
                    schoolinfo: {
                        //学校信息
                        contactpeople: "",
                        contactphone: "",
                        address: "",
                        jdcphone: "",
                        postcode: "",
                        province: "",
                        city: "",
                        name: "",
                    },
                    scoreinfo: [
                        //成绩信息
                        {
                            grade: "高一上(期末)",
                            gradeIndex: 0,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高一下(期末)",
                            gradeIndex: 1,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高二上(期末)",
                            gradeIndex: 2,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高二下(期末)",
                            gradeIndex: 3,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "高三上(期末)",
                            gradeIndex: 4,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                        {
                            grade: "最后大考",
                            gradeIndex: 5,
                            scoreItems: [
                                {
                                    subjectIndex: 0,
                                    myscore: "",
                                    subjectName: "语文",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 1,
                                    myscore: "",
                                    subjectName: "数学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 2,
                                    myscore: "",
                                    subjectName: "英语",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 3,
                                    myscore: "",
                                    subjectName: "物理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 4,
                                    myscore: "",
                                    subjectName: "化学",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 5,
                                    myscore: "",
                                    subjectName: "生物",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 6,
                                    myscore: "",
                                    subjectName: "政治",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 7,
                                    myscore: "",
                                    subjectName: "历史",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 8,
                                    myscore: "",
                                    subjectName: "地理",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 9,
                                    myscore: "",
                                    subjectName: "文理(综合)",
                                    totalscore: "",
                                },
                                {
                                    subjectIndex: 10,
                                    myscore: "",
                                    subjectName: "年级排名",
                                    totalscore: "",
                                },
                            ],
                        },
                    ],
                    wishinfo: [
                        //国内志愿
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                    ],
                    coopwishinfo: [
                        //国外志愿
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                        },
                    ],
                    qjwishinfo: [
                        //强基计划志愿
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                        {
                            zyunivid: "0",
                            zy1id: "0",
                            zy2id: "0",
                            zy3id: "0",
                            zyunivname: "",
                            zy1name: "",
                            zy2name: "",
                            zy3name: "",
                        },
                    ],

                },
                isDisable: false,
            }
        },
        components: {
            selfInfo,
        },
        created() {
            this.getAccountList();
        },
        methods: {
            //获取个人信息
            getAccountList() {
                getStep1()
                    .then((res) => {
                        console.log(res, "res");
                        if (res.data.code === 200) {
                            this.selfInfo = res.data.info;
                            console.log(this.selfInfo)
                            if (res.data.info.category) {
                                this.selfInfo.category = Number(this.selfInfo.category)
                            }
                            if (!res.data.info.familyinfo) {
                                this.selfInfo.familyinfo = [
                                    //家庭信息
                                    {
                                        career: "",
                                        educationdegree: "",
                                        job: "",
                                        name: "",
                                        phone: "",
                                        relation: "",
                                        workplace: "",
                                    },
                                    {
                                        career: "",
                                        educationdegree: "",
                                        job: "",
                                        name: "",
                                        phone: "",
                                        relation: "",
                                        workplace: "",
                                    },
                                ];
                            }
                            if (!res.data.info.schoolinfo) {
                                this.selfInfo.schoolinfo = {
                                    //学校信息
                                    contactpeople: "",
                                    contactphone: "",
                                    address: "",
                                    jdcphone: "",
                                    postcode: "",
                                    province: "",
                                    city: "",
                                    name: "",
                                };
                            }
                            if (!res.data.info.scoreinfo || res.data.info.scoreinfo.length <= 0) {
                                this.selfInfo.scoreinfo = [
                                    //成绩信息
                                    {
                                        grade: "高一上(期末)",
                                        gradeIndex: 0,
                                        scoreItems: [
                                            {
                                                subjectIndex: 0,
                                                myscore: "",
                                                subjectName: "语文",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 1,
                                                myscore: "",
                                                subjectName: "数学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 2,
                                                myscore: "",
                                                subjectName: "英语",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 3,
                                                myscore: "",
                                                subjectName: "物理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 4,
                                                myscore: "",
                                                subjectName: "化学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 5,
                                                myscore: "",
                                                subjectName: "生物",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 6,
                                                myscore: "",
                                                subjectName: "政治",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 7,
                                                myscore: "",
                                                subjectName: "历史",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 8,
                                                myscore: "",
                                                subjectName: "地理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 9,
                                                myscore: "",
                                                subjectName: "文理(综合)",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 10,
                                                myscore: "",
                                                subjectName: "年级排名",
                                                totalscore: "",
                                            },
                                        ],
                                    },
                                    {
                                        grade: "高一下(期末)",
                                        gradeIndex: 1,
                                        scoreItems: [
                                            {
                                                subjectIndex: 0,
                                                myscore: "",
                                                subjectName: "语文",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 1,
                                                myscore: "",
                                                subjectName: "数学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 2,
                                                myscore: "",
                                                subjectName: "英语",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 3,
                                                myscore: "",
                                                subjectName: "物理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 4,
                                                myscore: "",
                                                subjectName: "化学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 5,
                                                myscore: "",
                                                subjectName: "生物",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 6,
                                                myscore: "",
                                                subjectName: "政治",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 7,
                                                myscore: "",
                                                subjectName: "历史",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 8,
                                                myscore: "",
                                                subjectName: "地理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 9,
                                                myscore: "",
                                                subjectName: "文理(综合)",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 10,
                                                myscore: "",
                                                subjectName: "年级排名",
                                                totalscore: "",
                                            },
                                        ],
                                    },
                                    {
                                        grade: "高二上(期末)",
                                        gradeIndex: 2,
                                        scoreItems: [
                                            {
                                                subjectIndex: 0,
                                                myscore: "",
                                                subjectName: "语文",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 1,
                                                myscore: "",
                                                subjectName: "数学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 2,
                                                myscore: "",
                                                subjectName: "英语",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 3,
                                                myscore: "",
                                                subjectName: "物理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 4,
                                                myscore: "",
                                                subjectName: "化学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 5,
                                                myscore: "",
                                                subjectName: "生物",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 6,
                                                myscore: "",
                                                subjectName: "政治",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 7,
                                                myscore: "",
                                                subjectName: "历史",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 8,
                                                myscore: "",
                                                subjectName: "地理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 9,
                                                myscore: "",
                                                subjectName: "文理(综合)",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 10,
                                                myscore: "",
                                                subjectName: "年级排名",
                                                totalscore: "",
                                            },
                                        ],
                                    },
                                    {
                                        grade: "高二下(期末)",
                                        gradeIndex: 3,
                                        scoreItems: [
                                            {
                                                subjectIndex: 0,
                                                myscore: "",
                                                subjectName: "语文",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 1,
                                                myscore: "",
                                                subjectName: "数学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 2,
                                                myscore: "",
                                                subjectName: "英语",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 3,
                                                myscore: "",
                                                subjectName: "物理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 4,
                                                myscore: "",
                                                subjectName: "化学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 5,
                                                myscore: "",
                                                subjectName: "生物",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 6,
                                                myscore: "",
                                                subjectName: "政治",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 7,
                                                myscore: "",
                                                subjectName: "历史",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 8,
                                                myscore: "",
                                                subjectName: "地理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 9,
                                                myscore: "",
                                                subjectName: "文理(综合)",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 10,
                                                myscore: "",
                                                subjectName: "年级排名",
                                                totalscore: "",
                                            },
                                        ],
                                    },
                                    {
                                        grade: "高三上(期末)",
                                        gradeIndex: 4,
                                        scoreItems: [
                                            {
                                                subjectIndex: 0,
                                                myscore: "",
                                                subjectName: "语文",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 1,
                                                myscore: "",
                                                subjectName: "数学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 2,
                                                myscore: "",
                                                subjectName: "英语",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 3,
                                                myscore: "",
                                                subjectName: "物理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 4,
                                                myscore: "",
                                                subjectName: "化学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 5,
                                                myscore: "",
                                                subjectName: "生物",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 6,
                                                myscore: "",
                                                subjectName: "政治",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 7,
                                                myscore: "",
                                                subjectName: "历史",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 8,
                                                myscore: "",
                                                subjectName: "地理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 9,
                                                myscore: "",
                                                subjectName: "文理(综合)",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 10,
                                                myscore: "",
                                                subjectName: "年级排名",
                                                totalscore: "",
                                            },
                                        ],
                                    },
                                    {
                                        grade: "最后大考",
                                        gradeIndex: 5,
                                        scoreItems: [
                                            {
                                                subjectIndex: 0,
                                                myscore: "",
                                                subjectName: "语文",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 1,
                                                myscore: "",
                                                subjectName: "数学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 2,
                                                myscore: "",
                                                subjectName: "英语",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 3,
                                                myscore: "",
                                                subjectName: "物理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 4,
                                                myscore: "",
                                                subjectName: "化学",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 5,
                                                myscore: "",
                                                subjectName: "生物",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 6,
                                                myscore: "",
                                                subjectName: "政治",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 7,
                                                myscore: "",
                                                subjectName: "历史",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 8,
                                                myscore: "",
                                                subjectName: "地理",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 9,
                                                myscore: "",
                                                subjectName: "文理(综合)",
                                                totalscore: "",
                                            },
                                            {
                                                subjectIndex: 10,
                                                myscore: "",
                                                subjectName: "年级排名",
                                                totalscore: "",
                                            },
                                        ],
                                    },
                                ];
                            }
                            if (!res.data.info.wishinfo) {
                                this.selfInfo.wishinfo = [
                                    //国内志愿
                                    {
                                        zyunivid: "0",
                                        zy1id: "0",
                                        zy2id: "0",
                                        zy3id: "0",
                                        zyunivname: "",
                                        zy1name: "",
                                        zy2name: "",
                                        zy3name: "",
                                    },
                                    {
                                        zyunivid: "0",
                                        zy1id: "0",
                                        zy2id: "0",
                                        zy3id: "0",
                                        zyunivname: "",
                                        zy1name: "",
                                        zy2name: "",
                                        zy3name: "",
                                    },
                                    {
                                        zyunivid: "0",
                                        zy1id: "0",
                                        zy2id: "0",
                                        zy3id: "0",
                                        zyunivname: "",
                                        zy1name: "",
                                        zy2name: "",
                                        zy3name: "",
                                    },
                                ];
                            } else {
                                this.selfInfo.wishinfo.map(item => {
                                    item.zyunivid = Number(item.zyunivid);
                                    item.zy1id = Number(item.zy1id);
                                    item.zy2id = Number(item.zy2id);
                                    item.zy3id = Number(item.zy3id);
                                })
                            }
                            if (!res.data.info.coopwishinfo) {
                                this.selfInfo.coopwishinfo = [
                                    //国外志愿
                                    {
                                        zyunivid: "0",
                                        zy1id: "0",
                                        zy2id: "0",
                                        zyunivname: "",
                                        zy1name: "",
                                        zy2name: "",
                                    },
                                    {
                                        zyunivid: "0",
                                        zy1id: "0",
                                        zy2id: "0",
                                        zyunivname: "",
                                        zy1name: "",
                                        zy2name: "",
                                    },
                                ];
                            } else {
                                this.selfInfo.coopwishinfo.map(item => {
                                    item.zyunivid = Number(item.zyunivid);
                                    item.zy1id = Number(item.zy1id);
                                    item.zy2id = Number(item.zy2id);
                                    item.zy3id = Number(item.zy3id);
                                })
                            }
                            if (!res.data.info.qjwishinfo) {
                                this.selfInfo.qjwishinfo = [
                                    //强基计划志愿
                                    {
                                        zyunivid: "0",
                                        zy1id: "0",
                                        zy2id: "0",
                                        zy3id: "0",
                                        zyunivname: "",
                                        zy1name: "",
                                        zy2name: "",
                                        zy3name: "",
                                    },
                                    {
                                        zyunivid: "0",
                                        zy1id: "0",
                                        zy2id: "0",
                                        zy3id: "0",
                                        zyunivname: "",
                                        zy1name: "",
                                        zy2name: "",
                                        zy3name: "",
                                    },
                                    {
                                        zyunivid: "0",
                                        zy1id: "0",
                                        zy2id: "0",
                                        zy3id: "0",
                                        zyunivname: "",
                                        zy1name: "",
                                        zy2name: "",
                                        zy3name: "",
                                    },
                                ];
                            } else {
                                this.selfInfo.qjwishinfo.map(item => {
                                    item.zyunivid = Number(item.zyunivid);
                                    item.zy1id = Number(item.zy1id);
                                    item.zy2id = Number(item.zy2id);
                                    item.zy3id = Number(item.zy3id);
                                })
                            }
                            if (!res.data.info.start_school_date) {
                                this.selfInfo.start_school_date = "";
                            }
                            // if (!res.data.info.schoolprovince) {
                            //   this.selfInfo.schoolprovince = "";
                            // }
                            // if (!res.data.info.schoolcity) {
                            //   this.selfInfo.schoolcity = "";
                            // }
                            // if (!res.data.info.school) {
                            //   this.selfInfo.school = "";
                            // }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                // if (this.examUuid && this.examUuid !== "") {
                //
                // } else {
                //   this.$message.error("获取uuid失败，请检查网络并刷新页面重试");
                // }
            },
            //返回
            back() {
                this.$router.go(-1);
            },
            //保存
            saveInfo() {
                let isWishinfo = false;
                let isCoopwishinfo = false;
                this.selfInfo.coopwishinfo.forEach((item, index) => {
                    if (item.zyunivid != 0) {
                        isWishinfo = true;
                    }
                })
                this.selfInfo.wishinfo.forEach((item, index) => {
                    if (item.zyunivid != 0) {
                        isCoopwishinfo = true;
                    }
                })
                if (this.selfInfo.share == null) {
                    this.$message.error('请选择是否同意将成绩共享至你所填写的志愿高校？')
                } else if (this.selfInfo.share == 1 && isWishinfo == false && isCoopwishinfo == false) {
                    this.$message.error('请填写志愿')
                } else {
                    this.$refs.selfInfos.getFormData();
                }
            },
            getValue(val) {
                val.gender = val.sex;
                val.schoolInfo = val.schoolinfo;
                setStudentInfo(val)
                    .then((res) => {
                        console.log(res, "res");
                        if (res.data.code === 200) {
                            this.$message.success("报名信息修改成功");
                            let flag = this.$route.query.flag;
                            if (flag) {
                                // 从报名处进入修改信息 需要跳转到报名指定位置
                                return signUpInitReq(val.exam_uuid);
                            }
                            this.$router.replace({name: "personalinfo"})
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },

        },
        watch: {
            selfInfo(val) {
                console.log(val)
            }
        }
    };
</script>

<style lang="scss" scoped>
    #main {
        width: 100%;
        min-height: 80%;
        overflow: auto;

        .el-main {
            width: 1124px;
            margin: 26px auto;
            padding: 0 50px;
        }

        .formBox {
            background: #fff;
            padding: 50px;

            .header {
                display: flex;
                align-items: center;
                height: 50px;
                padding: 30px 0;
                border-bottom: 1px solid #333333;

                .handle {
                    flex: 1.5;
                    text-align: right;
                }

                .btnBox {
                    flex: 1;
                    text-align: right;
                }
            }

            .selfInfo {
                .selfInfoBox {
                    display: flex;
                    margin-top: 10px;

                    .formRoom {
                        flex: 2;

                        .form-room-box {
                            display: block;
                            margin-top: 10px;
                            padding: 5px 0;
                            font-size: 12px;
                            font-weight: 400;
                            color: #333333;

                            .form-room-handle {
                                font-weight: 400;
                                font-size: 12px;
                                margin-top: 10px;

                                .must {
                                    color: red;
                                    padding-right: 5px;
                                }
                            }
                        }

                        .form-room-handle {
                            font-weight: 400;
                            font-size: 12px;
                            margin-top: 10px;
                        }

                        .form-room-item {
                            width: 83%;
                            display: flex;
                            align-items: baseline;
                            justify-content: space-between;
                            margin-top: 10px;

                            .form-room-handle {
                                font-weight: 400;
                                font-size: 12px;
                                margin-top: 10px;

                                .must {
                                    color: red;
                                    padding-right: 5px;
                                }
                            }
                        }
                    }

                    .pictureRoom {
                        flex: 1;
                        display: flex;
                        justify-content: space-between;

                        .picture {
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .testBox {
                                margin-top: 20px;

                                div {
                                    width: 60%;
                                    text-align: left;
                                    margin: 10px auto;
                                }
                            }
                        }
                    }
                }
            }

            .familyInfo {
                width: 80%;
                margin-top: 10px;

                .family-item {
                    margin-top: 10px;

                    .item-box {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .items {
                            margin-top: 10px;
                        }
                    }
                }
            }

            .schoolInfo {
                width: 80%;
                margin-top: 10px;

                .handle {
                    font-weight: bold;
                }

                .schoolName {
                    margin-top: 10px;

                    .handle {
                        font-weight: 400;
                        margin-top: 10px;
                    }

                    .box {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            .schoolRecord {
                .handle {
                    font-weight: bold;
                    margin-top: 10px;
                }

                .recordTable {
                    padding: 0px;

                    table {
                        width: 100%;
                        margin-top: 10px;

                        tr {
                            width: 100%;

                            th {
                                background: #f5f5f5;
                                padding: 10px 0;
                            }

                            td {
                                text-align: center;
                                width: 8%;
                                padding: 10px 0;

                                .gradint {
                                    width: 20px;
                                    border-bottom: 1px solid #000;
                                }
                            }
                        }
                    }
                }
            }

            .lastRecord {
                .handle {
                    font-weight: bold;
                    margin-top: 10px;
                }

                table {
                    width: 100%;
                    margin-top: 10px;

                    tr {
                        width: 100%;

                        th {
                            background: #f5f5f5;
                            padding: 10px 0;
                        }

                        td {
                            text-align: center;
                            width: 8%;
                            padding: 10px 0;

                            .gradint {
                                width: 20px;
                                border-bottom: 1px solid #000;
                            }
                        }
                    }
                }
            }

            .wash {
                width: 80%;

                .handle {
                    font-weight: bold;
                }

                .handle2 {
                    font-weight: bold;
                    font-size: 12px;
                    margin-top: 10px;
                }

                .wash-item-box {
                    width: 100%;

                    .hand {
                        margin-top: 10px;
                        font-weight: bold;
                        font-size: 12px;
                    }

                    .items-box {
                        margin-top: 10px;

                        .items {
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                }
            }
        }

        table {
            border: 1px solid #ccc;

            th {
                border: 1px solid #ccc;
            }

            td {
                border: 1px solid #ccc;
            }
        }
    }

    ::v-deep .el-input__inner {
        width: 250px;
        // margin-top: 10px;
        border: 1px solid #ccc;
    }

    ::v-deep .el-input--prefix .el-input__inner {
        width: 192px;
    }

    /*::v-deep .el-button {*/
    /*    background: #563279;*/
    /*    color: #fff;*/
    /*}*/

    ::v-deep [data-v-3c2af5e5] .el-input--prefix .el-input__inner {
        width: 130px;
    }

    // 上传图片
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    ::v-deep .avatar-uploader .el-upload:hover {
        border-color: #563279;
    }

    ::v-deep .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 137px;
        height: 208px;
        line-height: 178px;
        text-align: center;
    }

    ::v-deep .avatar {
        width: 137px;
        height: 208px;
        display: block;
    }

    ::v-deep .btn {
        width: 100%;
        padding-top: 20px;
        text-align: center;
        padding-bottom: 50px;

        .el-button {

        }

        .confirm {
            width: 90px;
            height: 30px;
            background: #563279;
            color: #fff;
            text-align: center;
            font-size: 12px;
            line-height: 6px;
        }

        .back {
            border: 1px solid #563279;
            color: #563279;

            &:focus {
                background: #C8BDD3;
                color: #563279;
                border-color: #563279;
            }

            &:hover {
                background: #C8BDD3;
                color: #563279;
                border-color: #563279;
            }

        }
    }

</style>
